import request from "@/utils/request";

export function add(data) {
  return request({
    url: "api/distributorGoodsDelivery",
    method: "post",
    data,
  });
}

export function edit(data) {
  return request({
    url: "api/distributorGoodsDelivery",
    method: "put",
    data,
  });
}

export function del(ids) {
  return request({
    url: "api/distributorGoodsDelivery",
    method: "delete",
    data: ids,
  });
}

export function batchAdd(data) {
  return request({
    url: "api/distributorGoodsDelivery/batch",
    method: "post",
    data,
  });
}

export default {
  add,
  edit,
  del,
  batchAdd,
};
