<template>
  <div class="app-container h flex">
    <el-card header="经销商列表" shadow="never" class="no-flex flexable" style="margin-right: 20px; width: 30%" key="dis_t">
      <div class="bc-l padding-10">
        <el-input v-model="query.nameOrErpId" clearable placeholder="ERP编号或企业简称" style="width: 300px" @change="toQuery">
          <el-button slot="append" icon="el-icon-search" size="mini" @click="toQuery"></el-button>
        </el-input>
      </div>
      <el-table v-loading="loading" row-key="id" :data="data" size="small" highlight-current-row @current-change="handleCurrentChange" style="width: 100%">
        <el-table-column prop="erpId" label="ERP编号" width="120" />
        <el-table-column prop="name" label="企业简称" show-overflow-tooltip />
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" layout="total, prev, pager, next" class="padding-10" @size-change="sizeChange" @current-change="pageChange" />
    </el-card>

    <delivery :distributor="current" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import delivery from "./delivery";

export default {
  mixins: [initData],
  components: { delivery },
  data() {
    return {
      current: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributor";
      const sort = "id,desc";
      this.query.enabled = true;
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    handleCurrentChange(row) {
      this.current = row;
    },
  },
};
</script>