<template>
  <el-card header="SPU交货周期" shadow="never" class="flex flexable" key="spu_t">
    <template v-if="distributor">
      <div class="head-container bc-l" style="padding: 10px 10px 5px 10px;">
        <el-input v-model="query.name" clearable placeholder="产品搜索" style="width:200px" class="filter-item" @change="toQuery"/>
        <goods-selector button-text="添加SPU商品" :skuable="false" advance-query class="filter-item" :button-loading="saving" @submit="handleGoodsSet">
          <el-divider content-position="left">交货周期</el-divider>
          <el-input-number v-model="unify.addDeliveryCycle" :step="1" :min="1" :max="999" :precision="0" controls-position="right" @change="$checkNaN(unify, 'addDeliveryCycle', 7)" />
        </goods-selector>
        <el-button type="primary" :loading="saving" class="filter-item" :disabled="!selection || !selection.length" @click="doBatchEdit">统一修改交货周期</el-button>
        <el-button type="danger" :loading="deling" class="filter-item" :disabled="!selection || !selection.length" @click="doBatchDel">删除已勾选SPU</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table ref="list" v-loading="loading" row-key="id" :data="data" size="small" @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column type="selection" width="35" reserve-selection fixed />
        <el-table-column prop="spuName" label="商品名称" min-width="200" show-overflow-tooltip />
        <el-table-column prop="categoryName" label="分类" show-overflow-tooltip />
        <el-table-column prop="brandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="seriesName" label="系列" show-overflow-tooltip />
        <el-table-column label="交货周期" width="160">
          <template slot-scope="scope">
            <el-input v-model="scope.row.deliveryCycle" style="width: 100%;" @change="doEdit(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column width="60">
          <div class="row-commands" slot-scope="scope">
            <el-button type="text" size="mini" class="danger" @click="doDel(scope.row)">删除</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" layout="total, prev, pager, next" class="padding-10" @size-change="sizeChange" @current-change="pageChange" />
    </template>
    <div class="padding-10 ta-c fc-g" v-else>请在左侧列表中选择经销商</div>
  </el-card>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import goodsSelector from "@/views/assembly/goodsSelect";
import deliveryApi from "@/api/distributorDelivery";

export default {
  mixins: [initData],
  components: { goodsSelector },
  props: {
    distributor: Object,
  },
  data() {
    return {
      unify: { deliveryCycle: 7, addDeliveryCycle: 7 },
      saving: false,
      deling: false,
      selection: null,
    };
  },
  watch: {
    distributor: "toQuery",
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (this.distributor && this.distributor.enterpriseId) {
        this.url = "api/distributorGoodsDelivery";
        this.params = {
          distributorEntId: this.distributor.enterpriseId,
          spuName:this.query.name
        };
        return true;
      }
      return false;
    },
    handleGoodsSet(goods) {
      if (goods && goods.spus && goods.spus.length) {
        this.doSubmitBatchAdd(
          goods.spus.map((o) => o.id),
          this.unify.addDeliveryCycle
        );
      }
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    doBatchEdit() {
      if (this.selection && this.selection.length) {
        this.$prompt("请输入交货周期：", "统一调整", {
          inputPattern: /\d+/,
          imputErrorMessage: "请输入天数",
        }).then(({ value }) => {
          this.doSubmitBatchAdd(
            this.selection.map((d) => d.spuId),
            value
          );
        });
      }
    },
    doEdit(item) {
      if (!item.deliveryCycle || isNaN(item.deliveryCycle)) {
        item.deliveryCycle = 7;
      }
      deliveryApi
        .edit(item)
        .then(() => {
          this.$message.success("修改交货周期成功");
        })
        .catch(() => {
          this.init();
        });
    },
    doSubmitBatchAdd(spuIds, cycle) {
      if (this.distributor) {
        this.saving = true;
        let loader = this.$loading({ text: "正在保存交货周期…", lock: true });
        deliveryApi
          .batchAdd({
            deliveryCycle: cycle,
            distributorEntId: this.distributor.enterpriseId,
            spuIds,
          })
          .then(() => {
            this.init();
          })
          .finally(() => {
            this.saving = false;
            loader.close();
          });
      }
    },
    doDel(item) {
      this.$confirm("确定要删除该交货周期吗？", "操作确认", {
        type: "warning",
      }).then(() => {
        deliveryApi.del([item.id]).then(() => {
          this.$message.success("删除成功");
          this.init();
        });
      });
    },
    doBatchDel() {
      if (this.selection && this.selection.length) {
        this.$confirm("确定要删除勾选的交货周期吗？", "操作确认", {
          type: "warning",
        }).then(() => {
          this.deling = true;
          deliveryApi
            .del(this.selection.map((d) => d.id))
            .then(() => {
              this.$message.success("删除成功");
              this.init();
            })
            .finally(() => {
              this.deling = false;
            });
        });
      }
    },
  },
};
</script>