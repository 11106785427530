<template>
  <div>
    <el-button :type="buttonType" :size="buttonSize" @click="open" v-if="showButton"><slot name="button">{{buttonText}}</slot></el-button>

    <el-dialog :visible.sync="dialog" :title="title" append-to-body :close-on-click-modal="false" :show-close="false" @closed="handleClosed" width="1200px">
      <div class="fc-g" style="margin-bottom: 12px;" v-if="tip">{{tip}}</div>
      <div class="head-container">
        <template v-if="advanceQuery">
          <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" clearable style="width: 200px;" />
          <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 200px;" />
          <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 200px;" />
        </template>
        <el-input v-model="query.name" @keypress.enter.native="toQuery" @clear="toQuery" :validate-event="false" clearable placeholder="输入商品名称进行搜索" class="filter-item" style="width: 240px;" />
        <el-button type="success" icon="el-icon-search" class="filter-item" @click.stop="toQuery">搜索</el-button>
      </div>
      <div class="h">        
        <div :style="{'width': skuable?'40%':'100%', 'margin-right': '20px'}">
          <el-table ref="spu" row-key="id" border :data="data" :row-class-name="getSpuRowClassName" highlight-current-row @current-change="handleSpuSelectChange" @selection-change="handleSpuSelectionChange">
            <el-table-column type="selection" width="35" reserve-selection :selectable="checkSpuSelectable" v-if="multiple && spuable" />
            <el-table-column prop="name" label="商品名称" show-overflow-tooltip />
            <el-table-column prop="brandName" label="品牌" show-overflow-tooltip />
            <el-table-column prop="categoryName" label="分类" show-overflow-tooltip />
            <el-table-column prop="seriesName" label="系列" show-overflow-tooltip />
          </el-table>
          <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next" @size-change="sizeChange" @current-change="pageChange" />
        </div>
        <div class="flex" v-if="skuable">
          <el-table ref="sku" row-key="id" border :data="skus" :row-class-name="getSkuRowClassName" highlight-current-row @current-change="handleSkuSelectChange" @selection-change="handleSkuSelectionChange" v-loading="skuLoading" height="450px">
            <el-table-column type="selection" width="35" reserve-selection :selectable="checkSkuSelectable" v-if="multiple && skuable" />
            <el-table-column prop="code" label="商品编号" width="130" />
            <el-table-column prop="erpCode" label="ERP编号" width="130" />
            <el-table-column prop="specs" label="规格" show-overflow-tooltip :formatter="$goodsSpecConvert" />
          </el-table>
        </div>
      </div>
      <slot></slot>
      <div style="margin-top: 12px;">
        <el-button type="primary" :disabled="unsubmitable" @click="handleSubmit">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { getBasicSkus } from "@/api/goods";

export default {
  mixins: [initData],
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    buttonType: {
      type: String,
      default: "primary"
    },
    buttonSize: {
      type: String,
      default: "small"
    },
    buttonText: {
      type: String,
      default: "选择商品"
    },
    advanceQuery: Boolean,
    multiple: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "选择商品"
    },
    tip: String,
    requestUrl: {
      type: String,
      default: "api/goods"
    },
    spuable: {
      type: Boolean,
      default: true
    },
    skuable: {
      type: Boolean,
      default: true
    },
    requestParams: Object,
    excludeSpus: Array,
    excludeSkus: Array
  },
  data() {
    return {
      dialog: false,
      query: {
        name: "",
        brandId: null,
        categoryId: null,
        seriesId: null
      },
      skuLoading: false,
      inited: false,
      currentSpu: null,
      currentSku: null,
      skus: [],
      spuSelection: [],
      skuSelection: []
    };
  },
  computed: {
    unsubmitable() {
      if (this.multiple) {
        if (!this.spuable)
          return !this.skuSelection || !this.skuSelection.length;
        if (!this.skuable)
          return !this.spuSelection || !this.spuSelection.length;
        return (
          (!this.spuSelection || !this.spuSelection.length) &&
          (!this.skuSelection || !this.skuSelection.length)
        );
      } else {
        if (!this.spuable) return !this.currentSku;
        if (!this.skuable) return !this.currentSpu;
        return !this.currentSpu && !this.currentSku;
      }
    }
  },
  methods: {
    checkSpuSelectable(row) {
      let enable = true;
      if (this.excludeSpus && this.excludeSpus.length) {
        enable = this.excludeSpus.indexOf(row.id) < 0;
      }
      return enable;
    },
    checkSkuSelectable(row) {
      let enable = true;
      if (this.excludeSkus && this.excludeSkus.length) {
        enable = this.excludeSkus.indexOf(row.id) < 0;
      }
      if (this.spuable) {
        if (enable && this.excludeSpus && this.excludeSpus.length) {
          enable = this.excludeSpus.indexOf(row.spuId) < 0;
        }
        if (enable && this.spuSelection && this.spuSelection.length) {
          enable =
            this.spuSelection.findIndex(o => {
              return o.id === row.spuId;
            }) < 0;
        }
      }
      return enable;
    },
    getSpuRowClassName(scope) {
      if (
        !this.multiple &&
        this.spuable &&
        this.excludeSpus &&
        this.excludeSpus.length
      ) {
        return this.excludeSpus.indexOf(scope.row.id) < 0
          ? ""
          : "el-table--disabled-row";
      }
      return "";
    },
    getSkuRowClassName(scope) {
      if (
        !this.multiple &&
        this.skuable &&
        this.excludeSkus &&
        this.excludeSkus.length
      ) {
        return this.excludeSkus.indexOf(scope.row.id) < 0
          ? ""
          : "el-table--disabled-row";
      }
      return "";
    },
    beforeInit() {
      this.url = this.requestUrl;
      this.params = Object.assign(
        {},
        this.query || {},
        this.requestParams || {}
      );
      this.inited = true;
      return true;
    },
    loadSkus() {
      if (this.currentSpu) {
        this.skuLoading = true;
        getBasicSkus(this.currentSpu.id)
          .then(res => {
            this.skus = res.filter(o => {
              o.name = this.currentSpu.name;
              o.price = this.currentSpu.price;
              return o.enable;
            });
          })
          .then(_ => {
            this.skuLoading = false;
          });
      }
    },
    handleSpuSelectionChange(s) {
      this.spuSelection = s;
    },
    handleSkuSelectionChange(s) {
      this.skuSelection = s;
    },
    handleSpuSelectChange(r) {
      this.currentSpu = r;
      this.currentSku = null;
      this.loadSkus();
    },
    handleSkuSelectChange(r) {
      this.currentSku = r;
    },
    open() {
      this.dialog = true;
      this.query.name = "";
      this.toQuery();
    },
    handleCancel() {
      this.$emit("cancel");
      this.dialog = false;
    },
    handleClosed() {
      this.spuSelection = [];
      this.skuSelection = [];
      this.currentSpu = null;
      this.currentSku = null;
      this.skus = [];
      this.$refs.spu && this.$refs.spu.setCurrentRow();
      this.$refs.spu && this.$refs.spu.clearSelection();
      this.$refs.sku && this.$refs.sku.setCurrentRow();
      this.$refs.sku && this.$refs.sku.clearSelection();
    },
    handleSubmit() {
      if (this.multiple) {
        let spus = [].concat(this.spuSelection),
          skus = [].concat(this.skuSelection);
        for (let i = 0; i < skus.length; i++) {
          let sku = skus[i];
          let inx = spus.findIndex(p => {
            return p.id === sku.spuId;
          });
          if (inx >= 0) {
            skus.splice(inx, 1);
            i--;
          }
        }
        // skus.forEach(o => {
        //   let inx = spus.findIndex(p => {
        //     return p.id === o.spuId;
        //   });
        //   if (inx >= 0) spus.splice(inx, 1);
        // });
        this.$emit("submit", {
          spus,
          skus
        });
      } else {
        this.$emit("submit", {
          spu: this.currentSku ? null : this.currentSpu,
          sku: this.currentSku ? this.currentSku : null
        });
      }
      this.dialog = false;
    }
  }
};
</script>